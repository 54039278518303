<template>
 <div>
        <v-row dense>
            <v-col cols="12" class="">
             <v-card class="ma-2">
              <v-toolbar dense flat class="pa-1">
                  <v-spacer/>
                   <v-spacer/>
                 <span style="width: 240px; margin-top: 24px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, setData()" />
                    </v-menu>
               </span>
              
              </v-toolbar>
              
               
                  <table class="va-table print_table3 mt-1 md-font text-left" cellspacing="0">
                     <tr>
                       <th  style="background: #eaeaea; padding: 4px">Game</th>
                        <th  style="background: #eaeaea; padding: 4px">Draw</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">AMOUNT</th>
                     </tr>
                      <tr v-for="(item, i) in $store.state.account_summary.gross" :key="i">
                          <td>
                            {{item.game}}
                          </td>
                          <td>
                            {{item.draw_date}}
                          </td>
                          <td class="text-right">
                            {{$money(item.gross)}}
                          </td>
                      </tr>
                  </table>
                   <v-col cols="12" class="text-right">
                        <span class="lg-font ma-1 text-primary"> TOTAL: {{$money(TOTAL)}}</span>
                    </v-col>

                    
                  <table class="va-table print_table3 mt-1 md-font text-left" cellspacing="0">
                     <tr>
                       <th  style="background: #eaeaea; padding: 4px">Game</th>
                        <th  style="background: #eaeaea; padding: 4px">Draw</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">Hits</th>
                     </tr>
                      <tr v-for="(item, i) in $store.state.account_summary.wins" :key="i">
                          <td>
                            {{item.game}}
                          </td>
                          <td>
                            {{item.draw_date}}
                          </td>
                          <td class="text-right">
                            {{$money(item.gross)}}
                          </td>
                      </tr>
                  </table>
                   <v-col cols="12" class="text-right">
                        <span class="lg-font ma-1 text-primary"> WIN: {{$money(WIN)}}</span>
                    </v-col>
                  
             </v-card>
            </v-col>
        </v-row>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      ticket: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      confirm: false,
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:'',
      game:'All Games'
    }),
    created() {
      this.PING()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.to_date = this.$moment().format("YYYY-MM-DD")
          this.setData()
        }
    },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      TOTAL(){
        if(this.$store.state.account_summary.gross == null) {
          return 0
        }
        var total =  this.$store.state.account_summary.gross.reduce((res, item)=>res += this.$IsNum(item.gross),0)
        return total
      },
       WIN(){
        if(this.$store.state.account_summary.wins == null) {
          return 0
        }
        var total =  this.$store.state.account_summary.wins.reduce((res, item)=>res += this.$IsNum(item.gross),0)
        return total
      },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      ticketEvent(){
        this.viewticket =false
      },
      setData() {
        var param = {}
        param.account = this.user.id
       // param.method = "per_account"
        param.from_date = this.to_date
        param.to_date = this.to_date
        param.method = "single_account_gross_per_draw"
        this.ACCOUNT_SUMMARY(param)
      }
    },
  }
</script>